//Firebase.js
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6zf5CkGxKWKVH066cASWzA-_pKlVD4xU",
  authDomain: "bloxe-chat.firebaseapp.com",
  projectId: "bloxe-chat",
  storageBucket: "bloxe-chat.appspot.com",
  messagingSenderId: "836633804117",
  appId: "1:836633804117:web:3cd725c1a866e71abbc3dc",
  measurementId: "G-2L5G2C364L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { app, analytics, firestore };
