// MusicPage.js
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import './MusicReleases.css';

const MusicReleases = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        ReactGA.pageview('/music-releases');
        const handleScroll = () => {
            const embeds = document.querySelectorAll('.embed');
            embeds.forEach(embed => {
                const embedTop = embed.getBoundingClientRect().top;
                const isVisible = embedTop < window.innerHeight;
                if (isVisible) {
                    embed.classList.add('is-visible');
                }
            });
        };

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth <= 600;

    return (
        <div id="releases" className="music-releases" style={{ margin: '0 10%' }}>
            {isMobile ? (
                <>
                    <iframe
                        title="sc5"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/album=212209007/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Houndstooth EP by Surrealist
                    </iframe>
                    <iframe
                        title="sc1"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/album=344470542/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Locator EP by Khinre
                    </iframe>
                    <iframe
                        title="sc1"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=3730311755/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Dust by Spice Merchants
                    </iframe>
                    <iframe
                        title="sc2"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=3513507936/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Higher Ground [VIP] by Moose
                    </iframe>
                    <iframe
                        title="sc3"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2251422100/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Little Bit Dub by Spice Merchants
                    </iframe>
                    <iframe
                        title="sc4"
                        style={{ border: '0', width: '100%', height: '42px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2670314591/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless
                    >
                        Old Gregg Bootleg by Skopez
                    </iframe>
                </>
            ) : (
                <>
                    <iframe
                        title="sc9"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/album=212209007/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Houndstooth EP by Surrealist
                    </iframe>
                    <iframe
                        title="sc5"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/album=344470542/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Locator EP by Khinre
                    </iframe>
                    <iframe
                        title="sc5"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=3730311755/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Dust by Spice Merchants
                    </iframe>
                    <iframe
                        title="sc6"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=3513507936/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Higher Ground [VIP] by Moose
                    </iframe>
                    <iframe
                        title="sc7"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2251422100/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Little Bit Dub by Spice Merchants
                    </iframe>
                    <iframe
                        title="sc8"
                        className="embed"
                        width="305px"
                        height="120px"
                        style={{ border: '0' }}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2670314591/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                        seamless
                    >
                        Old Gregg Bootleg by Skopez
                    </iframe>
                </>
            )}
        </div>
    );
};

export default MusicReleases;
