// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import PasswordPage from './PasswordPage';
import HomePage from './Home/HomePage';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import AudioPlayer from './AudioPlayer'; // Assuming you have this component
import PageNotFound from './PageNotFound'; // Import the new PageNotFound component

const TRACKING_ID = 'G-8WQS67NW1J'; // Replace with your actual Google Analytics tracking ID

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Initialize Google Analytics once
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AnalyticsWrapper />
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/home" replace />
              ) : (
                <PasswordPage onPasswordSuccess={() => setIsAuthenticated(true)} />
              )
            }
          />
          <Route
            path="/home"
            element={
              isAuthenticated ? (
                <HomePageWithAudio />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const AnalyticsWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    // Send page view to Google Analytics whenever the route changes
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

const HomePageWithAudio = () => (
  <div>
    <HomePage />
    <AudioPlayer /> {/* Include your AudioPlayer component here */}
  </div>
);

export default App;
