// src/MasteringPage.js
import React, { useEffect, useRef } from 'react';
import './MasteringPage.css';
import GradientBackground from './Background';
import ReactGA from 'react-ga';

const MasteringPage = () => {
  const colorStops = ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)'];

  const cardRef = useRef(null);

  const scrollIntoView = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const card = cardRef.current;
    const masteringSection = document.getElementById('mastering-section');

    const addWhiteBackground = () => masteringSection.classList.add('white-background');
    const removeWhiteBackground = () => masteringSection.classList.remove('white-background');

    card.addEventListener('mouseenter', addWhiteBackground);
    card.addEventListener('mouseleave', removeWhiteBackground);
    card.addEventListener('mouseenter', scrollIntoView);
    card.addEventListener('focus', addWhiteBackground);
    card.addEventListener('blur', removeWhiteBackground);

    // Add white background for mobile mode
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isMobile) {
      addWhiteBackground();
    }

    return () => {
      card.removeEventListener('mouseenter', addWhiteBackground);
      card.removeEventListener('mouseleave', removeWhiteBackground);
      card.removeEventListener('mouseenter', scrollIntoView);
      card.removeEventListener('focus', addWhiteBackground);
      card.removeEventListener('blur', removeWhiteBackground);
    };
  }, []);

  const handleJoinDiscord = () => {
    // Send event to Google Analytics
    ReactGA.event({
      category: 'Mastering',
      action: 'Click',
      label: 'Join Discord',
    });
    window.location.href = 'https://discord.gg/CtArVyeNER';
  };

  return (
    <GradientBackground colorStops={colorStops}>
      <div id="mastering-section" className="mastering-section">
        <div className="card" ref={cardRef}>
          <h2 className="mastering-title">MASTERING</h2>
          <div className="additional-content">
            <h3>Why Master with Bloxe.Art?</h3>
            <p>
              Mastering is essential for delivering a club-ready track. Bloxe.Art has an experienced team of audio engineers equipped with a variety of hardware and software to ensure that your music is prepared for distribution.
            </p>
            <h3>How do I get started?</h3>
            <p>
              Join our Discord community for mastering services and support.
            </p>
            <div className="button-container">
              <button onClick={handleJoinDiscord}>Join Discord</button>
            </div>
            <h2 className="mastering-title">MASTERING PRICES</h2>
            <ul className="price-list">
              <li>1x TRACK: £7</li>
              <li>2x TRACKS: £13</li>
              <li>3x TRACKS: £19</li>
              <li>4x TRACKS: £25</li>
              <li>+ £5 THEN ON</li>
            </ul>
            <p className="stem-mastering-note">PLEASE ENQUIRE FOR MIXDOWNS</p>
          </div>
        </div>
      </div>
    </GradientBackground>
  );
};

export default MasteringPage;
