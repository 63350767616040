// src/Navbar.js
import React from 'react';
import './Navbar.css';
import ReactGA from 'react-ga';

const Navbar = ({ onPlayAudio, showContent }) => { // Accept showContent as a prop
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 60, // Adjusted for fixed navbar height
        behavior: 'smooth',
      });
    }
  };

  const handleNavClick = (sectionName) => {
    // Send event to Google Analytics
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: sectionName,
    });
    scrollToSection(sectionName);
  };

  const handlePlayAudio = () => {
    // Send event to Google Analytics
    ReactGA.event({
      category: 'Audio',
      action: 'Play',
      label: '0_0 Button',
    });
    onPlayAudio();
  };

  return (
    <nav className={`navbar ${showContent ? 'show' : ''}`}> {/* Apply 'show' class based on showContent */}
      <ul className="navbar-menu">
        <li className="navbar-item">
          <button
            className="navbar-link-button"
            onClick={() => handleNavClick('releases')}
          >
            Releases
          </button>
        </li>
        <li className="navbar-item">
          <button
            className="navbar-link-button"
            onClick={() => handleNavClick('mastering-section')}
          >
            Mastering
          </button>
        </li>
        <li className="navbar-item navbar-item-right">
          <button className="navbar-link-button" onClick={handlePlayAudio}>
            0_0
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
