// src/PageNotFound.js
import React from 'react';
import GradientBackground from './Home/Background'; // Adjusted import path

const PageNotFound = () => {
  const colorStops = ['#efe7e4', '#fcd5ce', '#f8a5a1', '#f78f86']; // Use the same colors as your background

  return (
    <GradientBackground colorStops={colorStops}>
      {/* Empty content for a blank page */}
    </GradientBackground>
  );
};

export default PageNotFound;
