// src/Home/HomePage.js
import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Navbar';
import MusicPage from '../MusicReleases/MusicPage';
import Chat from './Chat';
import MasteringPage from './MasteringPage';
import AudioPlayer from '../AudioPlayer';
import GradientBackground from './Background'; // Import GradientBackground
import './HomePage.css';

const HomePage = () => {
  const [active, setActive] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const audioPlayerRef = useRef(null);
  const [showChat, setShowChat] = useState(false);
  const hoverTimeoutRef = useRef(null); // Reference to store the timeout ID

  const handleMouseOver = () => {
    // Clear any existing timeout
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setActive(true);
  };

  const handleMouseLeave = () => {
    // Start a timer to set 'active' to false after 3 seconds
    hoverTimeoutRef.current = setTimeout(() => {
      setActive(false);
      hoverTimeoutRef.current = null;
    }, 3000);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handlePlayAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.playAudio();
    }
  };

  useEffect(() => {
    setShowContent(true);

    const handleScroll = () => {
      const chatSection = document.getElementById('chat');
      if (chatSection) {
        const chatSectionBottom = chatSection.offsetTop + chatSection.offsetHeight;
        const windowBottom = window.pageYOffset + window.innerHeight;

        if (windowBottom >= chatSectionBottom) {
          setShowChat(true);
        } else {
          setShowChat(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Clear the hover timeout if the component unmounts
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  const colorStops = ['#efe7e4', '#fcd5ce', '#f8a5a1', '#f78f86']; // Define gradient colors

  return (
    <GradientBackground colorStops={colorStops}>
      <Navbar onPlayAudio={handlePlayAudio} showContent={showContent} /> {/* Pass showContent as a prop */}
      <div
        className={`home-content ${showContent ? 'show' : ''}`}
        onClick={() => scrollToSection('chat')}
      >
        <h1>
          <span
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            className={active ? 'image-active' : ''}
          >
            BLOXE
          </span>{' '}
          NOT BLOKE
        </h1>
      </div>
      <MusicPage />
      <MasteringPage />
      {showChat && <Chat id="chat" />} {/* Render Chat based on showChat state */}
      <AudioPlayer ref={audioPlayerRef} />
    </GradientBackground>
  );
};

export default HomePage;
