// Background.js
import React, { useState, useEffect, useCallback } from 'react';

const GradientBackground = ({ colorStops, children }) => {
  const [backgroundColor, setBackgroundColor] = useState(colorStops && colorStops.length > 0 ? colorStops[0] : '#D9147D');

  const interpolateColor = (color1, color2, factor = 0.5) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPosition = scrollY / height;

    const maxIndex = colorStops.length - 1;
    const preciseIndex = scrollPosition * maxIndex;
    const startIndex = Math.floor(preciseIndex);
    const endIndex = Math.min(maxIndex, startIndex + 1);

    // Check if colorStops are defined and in bounds
    if (colorStops && colorStops[startIndex] && colorStops[endIndex]) {
      const startColor = colorStops[startIndex].match(/\w\w/g).map((hex) => parseInt(hex, 16));
      const endColor = colorStops[endIndex].match(/\w\w/g).map((hex) => parseInt(hex, 16));

      const factor = preciseIndex - startIndex;
      const interpolatedColor = interpolateColor(startColor, endColor, factor);
      setBackgroundColor(`rgb(${interpolatedColor[0]}, ${interpolatedColor[1]}, ${interpolatedColor[2]})`);
    }
  }, [colorStops]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    document.body.style.outline = 'none';
    return () => {
      document.body.style.outline = '';
    };
  }, []);

  return <div style={{ backgroundColor: backgroundColor }}>{children}</div>;
};

export default GradientBackground;
