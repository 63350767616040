// src/AudioPlayer.js
import React, { useRef, useImperativeHandle, forwardRef } from 'react';

const AudioPlayer = forwardRef((props, ref) => {
  const audioRef = useRef(null);

  useImperativeHandle(ref, () => ({
    playAudio: () => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
    },
    pauseAudio: () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    },
  }));

  return <audio ref={audioRef} src="/0_0.mp3" />;
});

export default AudioPlayer;
