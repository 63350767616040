// src/PasswordPage.js
import React, { useEffect } from 'react';
import './PasswordPage.css';
import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';
import image4 from './images/image4.png';

const PasswordPage = ({ onPasswordSuccess }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // After the animation completes, call onPasswordSuccess
      onPasswordSuccess();
    }, 4000); // Total duration of the animation plus delay

    return () => clearTimeout(timer);
  }, [onPasswordSuccess]);

  return (
    <div className="password-page">
      <div className="wave-container">
        <img src={image1} alt="Symbol 1" className="wave-image image1" />
        <img src={image2} alt="Symbol 2" className="wave-image image2" />
        <img src={image3} alt="Symbol 3" className="wave-image image3" />
        <img src={image4} alt="Symbol 4" className="wave-image image4" />
      </div>
    </div>
  );
};

export default PasswordPage;
