// Chat.js
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { app, firestore } from './Firebase';
import { collection, onSnapshot, addDoc } from 'firebase/firestore'; // Import Firestore functions

import './Chat.css';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Set up a Firestore collection reference
    const messagesCollection = collection(firestore, 'messages');
  
    // Set up a listener to get real-time updates
    const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => doc.data());
      setMessages(newMessages);
      console.log('Connected to Firestore'); // Debug: log a message when connected to Firestore
    });
  
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
    // Include 'app' in the dependency array if you need to use it inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);
  
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && newMessage.trim() !== '') {
      // Track the event when a user sends a message in the chat
      ReactGA.event({
        category: 'Chat Interaction',
        action: 'User Sent Message',
        label: newMessage,
      });
  
      // Use Firestore to add a new message
      try {
        const docRef = await addDoc(collection(firestore, 'messages'), { text: newMessage, sender: 'user' });
        console.log("Document written with ID: ", docRef.id); // Debug: log the ID of the new document
        setNewMessage(''); // Clear the input after sending a message
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };
  return (
    <div id="chat" className="home-content chat-section">
      <div className="chat-header">
        <h2>Converse</h2>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender === 'user' ? 'user-message' : 'chat-message'}`}>
            {message.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder="What u sayin?"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ fontFamily: 'Helvetica', color: '#333' }}
        />
      </div>
    </div>
  );
};

export default Chat;